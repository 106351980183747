


























































.right {
  svg {
    transform: rotateY(180deg);
  }

  &.triangle {
    left: -11% !important;
    right: initial !important;
  }
  &.round {
    left: -11% !important;
    right: initial !important;
  }
  &.wave {
    left: -10% !important;
    right: initial !important;
  }
}

.triangle {
  right: -11% !important;
}
.round {
  right: -11% !important;
}
.wave {
  right: -10% !important;
}
